/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('is-external').attr('target', '_blank');

                // Navigation Dropdown
                $('.js-toggle-dropdown').click(function() {
                    $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
                });

                // Navigation
                $('.c-toggle-nav').click(function(e) {
                    e.preventDefault();

                    $(this).toggleClass('is-open');

                    $('.c-nav--mobile').toggleClass('is-open');
                });

                // Scroll to section
                $('.js-scroll-down').click(function(e) {
                    e.preventDefault();

                    var scrollTo = $(this).attr('href');

                    $('html, body').animate({
                        scrollTop: $(scrollTo).offset().top
                    }, 2000);
                });

                // Lightbox - Images
                if (viewportWidth >= 1024) {
                    // Fancybox
                    $('.js-lightbox-image').attr('data-fancybox', 'group').fancybox();
                }

                // Popup
                /*
                if ($("#mc_embed_signup").length) {
                    // Fire popup if visisted cookie isn't set
                    if (!Cookies.get('visisted')) {
                        $.fancybox.open({
                            src: '#mc_embed_signup',
                            type: 'inline'
                        });

                        Cookies.set('visisted', 'true', { expires: 365 });
                    }
                }*/

                // Site Scrolling
                $(window).scroll(function() {
                    var scrollY = $(this).scrollTop();

                    if (scrollY > 10) {
                        $('body').addClass('is-scrolling');
                    } else {
                        $('body').removeClass('is-scrolling');
                    }
                });

                // Hero
                var elHero              = $('.c-hero'),
                    mobileBackground    = elHero.data('mobile'),
                    desktopBackground   = elHero.data('desktop');

                if (viewportWidth > 1024) {
                    elHero.css('background-image', 'url('+desktopBackground+')');
                } else {
                    elHero.css('background-image', 'url('+mobileBackground+')');
                }

                // Masonry items
                /*$('.c-masonry-item').each(function() {
                    var backgroundImage = $(this).data('background');

                    $(this).css('background-image', 'url('+backgroundImage+')');
                });*/

                // Slick Sliders
                $('.js-slider').slick({
                    autoplay: true,
                    prevArrow: '<a class="slick-prev slick-arrow" href="javascript:void(0)"></a>',
                    nextArrow: '<a class="slick-next slick-arrow" href="javascript:void(0)"></a>',
                    pauseOnHover: true
                });

                // Find images what will slide in
                var slideImages = $('.js-animate');

                function slideInImages(e) {
                    slideImages.each(function() {
                        // Find half way point of image
                        var imageHalf = (window.scrollY + window.innerHeight) - ($(this).height() / 2);

                        // Find image bottom
                        var imageBottom = ($(this).offset().top + $(this).height());

                        // Determine if image is half way displayed
                        var isHalfShown = imageHalf > ($(this).offset().top - 500);

                        // Determine if image has been scrolled past
                        var isNotScrolledPast = window.scrollY < imageBottom;

                        // Check if image is half way shown and not scrolled past
                        if (isHalfShown && isNotScrolledPast) {
                            $(this).addClass('is-active');
                        }
                    });
                }

                if (viewportWidth > 1023) {
                    window.addEventListener('scroll', $.debounce(5, slideInImages));
                }

                // PDF(s)
                $('a[href$=".pdf"]').each(function() {
                    $(this).attr('target', '_blank');
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
